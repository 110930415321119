import clsx from "clsx"
import moment from "moment/moment"
import StarsRating from "../../Assets/StarsRating/StarsRating"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import TextArea from "../../Assets/TextArea/TextArea"
import Button from "../../Assets/Button/Button"
import { IOrderWithoutReview, IReview } from "../../../types/content"
import { useTranslation } from "react-i18next"
import useWindowSize from "../../../hooks/useWindowSize"
import RatingModal from "../../Modals/RatingModal/RatingModal"
import { useLazySendReviewQuery } from "../../../redux/api/review"
import { useAppSelector } from "../../../hooks"
import { countOrdersWithoutReview, updateCountOrdersWithoutReview } from "../../../redux/slice/main"
import { useDispatch } from "react-redux"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import UserAvatars from "../../Assets/UserAvatars/UserAvatars"
import styles from "./ReviewForm.module.scss"

interface Props extends IOrderWithoutReview {
  setReviews: Dispatch<
    SetStateAction<{
      orders: IOrderWithoutReview[]
      reviews: IReview[]
    }>
  >
  setModalInfo: Dispatch<SetStateAction<{ chat_dialog_id: string; order_id: string }>>
  className?: string
}

const ReviewForm = ({
  id,
  chat_dialog_id,
  assigneeUsers,
  name,
  created_at,
  setReviews,
  setModalInfo,
  className,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const { isDesktop, isMobile } = useWindowSize()

  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)

  const [sendReview] = useLazySendReviewQuery()

  const [valueTxt, setValueTxt] = useState<string>("")
  const [ratingValue, setRatingValue] = useState<number>(0)
  const [openRatingModal, setOpenRatingModal] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const onSubmit = () => {
    if (isMobile) {
      setOpenRatingModal(true)
      return
    }
    if (isLoading) return
    setLoading(true)
    sendReview({ id: id, txt: valueTxt, rating: ratingValue })
      .then((res) => {
        if (!res?.data) return
        setReviews((prev) => {
          const newReview = res.data
          if (!newReview) return prev
          return {
            orders: [...prev.orders.filter((order) => order.id !== id)],
            reviews: [{ ...newReview, chat_dialog_id: chat_dialog_id }, ...prev.reviews],
          }
        })
        if (quantityOrdersWithoutReview) {
          dispatch(updateCountOrdersWithoutReview(quantityOrdersWithoutReview - 1))
        }
      })
      .catch((err) => console.error(err.message))
  }

  return (
    <>
      <div className={clsx(styles.item, className)}>
        <div className={styles.main}>
          <header className={styles.header}>
            <div className={styles.headerTop}>
              <h4 className={styles.title}>{name}</h4>
              {!isDesktop && (
                <StarsRating
                  size={isMobile ? "sm" : undefined}
                  name={"review-form-rate-" + id + 1}
                  rating={ratingValue}
                  disabled
                  className={styles.stars}
                />
              )}
              <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>
            </div>
            <div className={clsx(styles.headerBottom, assigneeUsers?.length < 1 && styles["headerBottom__right"])}>
              <div className={clsx(styles.assignees, !isDesktop && styles["assignees--more"])}>
                {isDesktop ? (
                  <>
                    {assigneeUsers?.map((assignee) => {
                      return (
                        <div key={assignee.id} className={styles.assignee}>
                          <GetUserAvatar
                            avatar={assignee?.avatar_id}
                            name={assignee?.name}
                            className={styles.avatar}
                            size={isDesktop ? "md" : "sm"}
                          />
                          <div>
                            <p className={styles.name}>{assignee.name}</p>
                          </div>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <>
                    <UserAvatars items={assigneeUsers} className={styles.avatars} />
                    <div>
                      {assigneeUsers?.map((assignee, index) => {
                        if (index > 4) return
                        const suf = index + 1 !== assigneeUsers.length ? ", " : ""
                        const more = index === 4 && assigneeUsers.length > 5 ? `еще ${assigneeUsers.length - 5}..` : ""
                        return (
                          <span key={assignee.id} className={styles.name}>
                            {assignee.name}
                            {suf}
                            <span>{more}</span>
                          </span>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>

              {isDesktop && (
                <StarsRating
                  size={isMobile ? "sm" : undefined}
                  name={"review-form-rate-" + id + 1}
                  rating={ratingValue}
                  onChange={(val) => {
                    if (isMobile) return
                    setRatingValue(val)
                  }}
                  disabled={isMobile}
                  className={styles.stars}
                />
              )}
            </div>
          </header>

          <div className={styles.bot}>
            <label className={styles.label}>{t("leaveComment")}</label>
            <TextArea
              autoSize
              placeholder={t("describeHowWent")}
              minRows={1}
              maxRows={5}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValueTxt(e.target.value)}
              value={valueTxt}
              className={styles.textarea}
            />
          </div>
        </div>

        <footer className={styles.footer}>
          <Button
            txt={t("goToReq")}
            mode={isMobile ? "gray" : "white"}
            className={styles.btn}
            onClick={() => {
              setModalInfo({ chat_dialog_id: chat_dialog_id, order_id: id })
            }}
          />
          <Button
            txt={t("giveFeedback")}
            disabled={!ratingValue && !isMobile ? true : undefined}
            className={styles.btn}
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </footer>
      </div>

      {openRatingModal && id && (
        <RatingModal open={openRatingModal} setOpen={setOpenRatingModal} orderId={id} setReviews={setReviews} />
      )}
    </>
  )
}

export default ReviewForm
