import { FC, useCallback, useEffect, useMemo, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import OrdersList from "../OrdersList/OrdersList"
import { useTranslation } from "react-i18next"
import { useLazyPostServiceOrdersQuery } from "../../redux/api/content"
import { useLocation, useNavigate } from "react-router-dom"
import RatingModal from "../Modals/RatingModal/RatingModal"
import { useDispatch } from "react-redux"
import { clearMessageCountId, setNeedClearNewMessageCount, updateOrdersList } from "../../redux/slice/ordersList"
import { useAppSelector } from "../../hooks"
import Search from "../Search/Search"
import _debounce from "lodash/debounce"
import { IChatInfo, ILastMessage } from "../../types/chat"
import { IServiceOrderShort } from "../../types/content"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import { setMobileMenuIsHidden } from "../../redux/slice/isMoreModal"
import { selectUser } from "../../redux/slice/auth"
import { IServiceOrderWithReview } from "../../types/orderTypes"
import { Channel } from "laravel-echo"

interface Props {
  layout?: "history" | "active"
}

const OrdersLayout: FC<Props> = ({ layout }) => {
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const [orderInfoModal, setOrderInfoModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)
  const [getActiveOrders, { data: requestsData, isLoading }] = useLazyPostServiceOrdersQuery()
  const [currentPage, setCurrentPage] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [isHaveMore, setIsHaveMore] = useState<boolean | undefined>(undefined)
  const [orders, setOrders] = useState<IServiceOrderShort[]>([])
  const [openedOrder, setOpenedOrder] = useState<string | undefined>(undefined)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [searchVal, setSearchVal] = useState<string>("")
  const [chatID, setChatID] = useState<string>("")
  const [openModalHash, setOpenModalHash] = useState(false)

  const clearMessageCountIdStr = useAppSelector(clearMessageCountId)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const hash = location.hash

  const orderClickFcn = (order: IServiceOrderShort) => {
    dispatch(setMobileMenuIsHidden(true))
    setOrderInfoModal(true)
    setOpenedOrder(order.id)

    setChatID(order.chat_dialog_id)
    navigate("#orderModal")
  }

  useEffect(() => {
    setOpenModalHash(hash.includes("#orderModal"))
  }, [hash])

  const closeOrderInfoModal = () => {
    setOrderInfoModal(false)
    navigate(location.pathname)
  }

  const ratingOpenFn = (id: string) => {
    setIsRatingModal(true)
    setOpenedOrder(id)
  }

  useEffect(() => {
    if (!user?.id || !orders?.length) return

    const channel: Channel = window.Echo?.private(`privateUser.${user?.id}`)

    if (layout === "active") {
      //TODO: remove? замена на changeStatusPublic?
      // channel?.listen(".resolvePublicOrder", (event: { id: string; status_alias: "resolved" | "cancelled" }) => {
      //   if (!event?.id) return
      //   setOrders((ords) => [...ords.filter((el) => el.id !== event.id)])
      // })
    }

    channel?.listen(".newMessageInDialog", (event: IChatInfo) => {
      setOrders((prev) => {
        const index = prev.findIndex((i) => i.id === event.order_id)
        const clone = [...prev]
        if ((index !== 0 && !index) || !clone[index] || (orderInfoModal && openedOrder === event.order_id)) {
          return prev
        }
        clone[index] = { ...clone[index], message_counts: event.unreaded_messages }
        return clone
      })
    })
    channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      if (event.is_readed) return
      //Если сообщение еще не было прочитанным, то уменьшаем
      setOrders((prev) => {
        const index = prev.findIndex((i) => i.chat_dialog_id === event.chat_dialog_id)
        const clone = [...prev]
        if ((index !== 0 && !index) || !clone[index] || (orderInfoModal && openedOrder === clone[index].id)) {
          return prev
        }
        clone[index] = {
          ...clone[index],
          message_counts:
            clone[index].message_counts > 0 ? clone[index].message_counts - 1 : clone[index].message_counts,
        }
        return clone
      })
    })

    return () => {
      channel.stopListening(`.newMessageInDialog`)
      channel.stopListening(`.deleteMessageInDialog`)
    }
  }, [layout, user, orders, orderInfoModal, openedOrder])

  useEffect(() => {
    if (!user?.id) return
    const channel: Channel = window.Echo?.private(`privateUser.${user?.id}`)

    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      const newOrder: IServiceOrderShort = {
        id: event.id,
        chat_dialog_id: event.chat_dialog_id,
        name: event.name,
        created_at: event.created_at as any,
        message_counts: 0,
        hasReview: event.hasReview,
        count: event.count,
        number: event.number,
        path_name: event.path_name,
        photos: event.photos,
        status: event.status,
      }
      setOrders((prev) => {
        if ((event.status === 0 && layout === "active") || (event.status !== 0 && layout === "history")) {
          // если мы на странице Активные заявки и заявка стала активной
          // или если мы на странице История и заявка стала неактивной
          const isExist = prev.some((order) => order.id === event.order_id)
          if (isExist) return prev
          //TODO: в зависимости от даты, вставить на нужное место в Orders
          // isHaveMore - проверять, если уже все загружены, то вставить в нужное место в Orders
          // если не все загружены, то проверить, есть ли уже эта дата или дата после, и тогда вставить в нужное место в orders, иначе, ничего не делать(рассчитываем на то, что эта заявка подгрузится после по скролу)
          // return [newOrder, ...prev]
          return prev
        }
        if ((event.status === 0 && layout === "history") || (event.status !== 0 && layout === "active")) {
          // если страница История и заявка стала активной
          // или страница Активный заявок и заявка стала неактивной
          return orders.filter((order) => order.id !== event.id)
        }
        return prev
      })
    })
  }, [layout, user, isHaveMore])

  useEffect(() => {
    // при открытии модалки-заявки, обнуляем кол-во прочитанных сообщений у самой заявки, т.к. чат по ней будет открыт и сообщения станут прочитанными
    if (!orderInfoModal || !openedOrder) return
    setOrders((prev) => {
      const index = prev.findIndex((i) => i.id === openedOrder)
      const clone = [...prev]
      if ((index !== 0 && !index) || !clone[index]) return prev
      clone[index] = { ...clone[index], message_counts: 0 }
      return clone
    })
  }, [orderInfoModal, openedOrder])

  useEffect(() => {
    if (fetching && !isLoading) {
      if (isHaveMore !== undefined && !isHaveMore) {
        return
      } else {
        getActiveOrders({ limit: 7, offset: currentPage * 7, isActive: layout === "active" })
          .unwrap()
          .then((res) => {
            setOrders((ords) => [...ords, ...res.data.aItems])
            dispatch(updateOrdersList({ list: res.data.aItems }))
            setIsHaveMore(!res.data.bIsEnd)
            setFetching(false)
            setCurrentPage((prev) => prev + 1)
          })
      }
    }
  }, [fetching])

  useEffect(() => {
    getActiveOrders({ limit: 7, offset: currentPage * 7, isActive: layout === "active" })
      .unwrap()
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        })
        setOrders([...res.data.aItems])
        dispatch(updateOrdersList({ list: res.data.aItems }))
        setIsHaveMore(!res.data.bIsEnd)
        setIsFirstRender(false)
        setCurrentPage((prev) => prev + 1)
        checkHeightWindowForFetching()
      })
      .catch((err) => {
        if (err.status === 401) navigate("/")
        console.warn(err)
      })
    document.addEventListener("scroll", scrollHandler)
    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  // Если появляется айди (записываемое в модалке чата), то для него обнуляем счетчик непрочитанных сообщений
  useEffect(() => {
    if (clearMessageCountIdStr) {
      setOrders((ords) => [...ords.map((el) => (el.id === clearMessageCountIdStr ? { ...el, message_counts: 0 } : el))])
      dispatch(setNeedClearNewMessageCount(""))
    }
  }, [clearMessageCountIdStr])

  //Пока что закомментил для текста. Если все норм, то удалить потом
  // useEffect(() => {
  //   return () => {
  //     if (!isDesktop) {
  //       dispatch(setMobileMenuIsHidden(false))
  //       allowScroll(true)
  //     }
  //   }
  // })

  const checkHeightWindowForFetching = () => {
    const windowHeight = window.innerHeight
    const mainHeight = document.querySelector("main")?.offsetHeight

    if (mainHeight) {
      if (windowHeight >= mainHeight && !isLoading) {
        setFetching(true)
      }
    }
  }

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 80) {
      if (!isLoading) {
        setFetching(true)
      }
    }
  }

  // ф-я удаления из списка текущих заявок при отмене
  const delOrderFromList = (orderId: string) => {
    setOrders((ords) => [...ords.filter((el) => el.id !== orderId)])
  }

  const [ordersSearch, setOrdersSearch] = useState<string>("")
  const debounceFn = useCallback(
    _debounce((str: string) => {
      setOrdersSearch(str)
    }, 500),
    [],
  )
  useEffect(() => {
    debounceFn(searchVal)
  }, [searchVal])

  const ordersList = useMemo(() => {
    if (!ordersSearch) return orders
    return orders.filter(({ name }) => name?.toLowerCase().includes(ordersSearch.toLowerCase()))
  }, [orders, ordersSearch])

  return (
    <>
      <TitleBack title={layout === "active" ? t("actRequests") : t("historyRequests")} />
      <Search searchVal={searchVal} setSearchVal={setSearchVal} />
      <OrdersList
        layout={layout}
        orders={isFirstRender && requestsData ? [...requestsData.data.aItems] : ordersList}
        clickFcn={orderClickFcn}
        ratingFn={ratingOpenFn}
        isLoading={isLoading}
        isFirstRender={isFirstRender}
        isLoadingAppend={fetching && isHaveMore}
      />
      {orderInfoModal && openedOrder && openModalHash && (
        <OrderInfoModal
          id={openedOrder}
          chatID={chatID}
          open={orderInfoModal}
          setOpen={setOrderInfoModal}
          delFromList={delOrderFromList}
          layout={layout}
          closeModalFunc={closeOrderInfoModal}
        />
      )}
      {openedOrder && isRatingModal && (
        <RatingModal
          orderId={openedOrder}
          open={isRatingModal}
          setOpen={setIsRatingModal}
          onSubmit={(id) => {
            if (!id) return
            const index = orders.findIndex((i) => i.id === id)
            if (index !== 0 && !index) return
            setOrders((prev) => {
              const clone = [...prev]
              clone[index] = { ...clone[index], hasReview: true }
              return clone
            })
          }}
        />
      )}
    </>
  )
}

export default OrdersLayout
