import { useEffect } from "react"
import { useAppSelector } from "./index"
import { addActiveOrdersCount, removeActiveOrdersCount, selectUser } from "../redux/slice/auth"
import { IServiceOrderWithReview } from "../types/orderTypes"
import { useDispatch } from "react-redux"
import { increaseCountOrdersWithoutReview } from "../redux/slice/main"
import { Channel } from "laravel-echo"

const useChangeStatusOrder = () => {
  const dispatch = useDispatch()
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (!user?.id) return
    const channel: Channel = window?.Echo?.private(`privateUser.${user?.id}`)
    // .resolvePublicOrder - есть такое еще событие
    // меняем счётчик активных заявок и отзывов
    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      if (event.status === 0) {
        // если активная
        if (event.prev_status === -1) dispatch(addActiveOrdersCount())
      } else {
        // если неактивная
        dispatch(removeActiveOrdersCount())
        if (event.status === 1 && !event.hasReview) {
          dispatch(increaseCountOrdersWithoutReview())
        }
      }
    })
  }, [user?.id])
}

export default useChangeStatusOrder
