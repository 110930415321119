import { FC, useEffect, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import { useLazyGetOrdersWithoutReviewsQuery, useLazyGetReviewsQuery } from "../../redux/api/review"
import clsx from "clsx"
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll"
import { IOrderWithoutReview, IReview } from "../../types/content"
import Reviews from "../Reviews/Reviews"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { IServiceOrderWithReview } from "../../types/orderTypes"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import styles from "./ReviewsLayout.module.scss"
import moment from "moment"

const LIMIT_REVIEWS = 10

const ReviewsLayout: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getOrdersWR, { isFetching: isFetchingOrder, isUninitialized: isUninitializedOrders }] =
    useLazyGetOrdersWithoutReviewsQuery()
  const [getReviews, { isFetching: isFetchingReview, isUninitialized: isUninitializedReviews }] =
    useLazyGetReviewsQuery()

  const user = useAppSelector(selectUser)

  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false)
  const [modalInfo, setModalInfo] = useState<{ chat_dialog_id: string; order_id: string }>({
    chat_dialog_id: "",
    order_id: "",
  })
  const [isOrdersEnd, setOrdersEnd] = useState<boolean>(false)
  const [offsetOrder, setOffsetOrder] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [reviewsState, setReviewsState] = useState<{
    orders: IOrderWithoutReview[]
    reviews: IReview[]
  }>({
    orders: [],
    reviews: [],
  })

  useEffect(() => {
    if (!modalInfo?.chat_dialog_id) return
    setOpenOrderModal(true)
  }, [modalInfo])

  useEffect(() => {
    // первая загрузка заявок без отзывов
    getOrdersWR({ limit: LIMIT_REVIEWS })
      .unwrap()
      .then((res) => {
        setReviewsState((prev) => {
          return { ...prev, orders: res?.orders || [] }
        })
        setOrdersEnd(res.bIsEnd)
        setOffsetOrder((prev) => prev + LIMIT_REVIEWS)
      })
  }, [])

  useEffect(() => {
    // первая загрузка отзывов, если заявки без отзывов закончились
    if (!isOrdersEnd) return
    getReviews({ limit: LIMIT_REVIEWS })
      .unwrap()
      .then((res) => {
        setReviewsState((prev) => {
          return { ...prev, reviews: res?.reviews || [] }
        })
        if (res.bIsEnd) setListEnd(true)
        setOffset((prev) => prev + LIMIT_REVIEWS)
      })
  }, [isOrdersEnd])

  const fetchData = async () => {
    if (isOrdersEnd) {
      getReviews({ limit: LIMIT_REVIEWS, offset: offset })
        .unwrap()
        .then((res) => {
          setReviewsState((prev) => {
            return { ...prev, reviews: [...prev.reviews, ...res.reviews] }
          })
          setOffset((prev) => prev + LIMIT_REVIEWS)
          setListEnd(res.bIsEnd)
        })
    } else {
      getOrdersWR({ limit: LIMIT_REVIEWS, offset: offsetOrder })
        .unwrap()
        .then((res) => {
          setReviewsState((prev) => {
            return { ...prev, orders: [...prev.orders, ...res.orders] }
          })
          setOffsetOrder((prev) => prev + LIMIT_REVIEWS)
          setOrdersEnd(res.bIsEnd)
        })
    }
  }

  const infiniteScroll = useInfiniteScroll<any>(
    async () => {
      if (isListEnd || isFetchingReview || isFetchingOrder) return
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
        void fetchData()
      })
    },
    { distance: 200, isDocument: true },
  )

  useEffect(() => {
    if (!user?.id) return
    const channel = window?.Echo?.private(`privateUser.${user?.id}`)
    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      if (event.status === 0) {
        // если активная
        setReviewsState((prev) => {
          if (event?.hasReview) {
            return { ...prev, reviews: prev.reviews.filter((review) => review.order_id !== event.id) }
          } else {
            return { ...prev, orders: prev.orders.filter((order) => order.id !== event.id) }
          }
        })
      } else {
        // если неактивная
        if (event?.hasReview) {
          // если уже был отзыв
          const newReview: IReview = {
            assigneeUsers: event.assignees,
            chat_dialog_id: event.chat_dialog_id,
            created_at: event.reviewed_at,
            order_id: event.id,
            order_name: event.name,
            text: event?.score_comment || "",
            rating: event.score || 0,
          }
          setReviewsState((prev) => {
            return { ...prev, reviews: [newReview, ...prev.reviews] }
          })
        } else {
          // если отзыва еще не было
          const newOrder: IOrderWithoutReview = {
            assigneeUsers: event.assignees,
            chat_dialog_id: event.chat_dialog_id,
            created_at: event.finished_at ? moment(event.finished_at).unix() : moment().unix(),
            id: event.id,
            name: event.name,
          }
          setReviewsState((prev) => {
            return { ...prev, orders: [newOrder, ...prev.orders] }
          })
        }
      }
    })
  }, [user])

  return (
    <>
      <TitleBack title={t("myReviewsTitle")} noLinkBack className={styles.titleBack} />
      <div>
        {!isUninitializedOrders && (
          <>
            <Reviews reviews={reviewsState.orders} isOrder setReviews={setReviewsState} setModalInfo={setModalInfo} />
            <Reviews reviews={reviewsState.reviews} setReviews={setReviewsState} setModalInfo={setModalInfo} />
            {!isUninitializedReviews &&
              !reviewsState?.orders?.length &&
              !reviewsState?.reviews?.length &&
              !isFetchingReview &&
              !isFetchingOrder && <div className={styles.noReviews}>{t("noReviewsYet")}</div>}
          </>
        )}
        {(infiniteScroll.isLoading || isFetchingReview || isFetchingOrder) && !isListEnd && (
          <>
            <div className={clsx("skeletonBlock", styles.subtitleLoader)} />
            <div className={clsx("skeletonBlock", styles.itemLoader)} />
            <div className={clsx("skeletonBlock", styles.itemLoader)} />
          </>
        )}
      </div>

      {openOrderModal && modalInfo.chat_dialog_id && (
        <OrderInfoModal
          chatID={modalInfo.chat_dialog_id}
          id={modalInfo.order_id}
          open={openOrderModal}
          setOpen={setOpenOrderModal}
          layout={"history"}
          delFromList={(orderId) => {
            setReviewsState((prev) => {
              return {
                orders: [...prev.orders],
                reviews: [...prev.reviews.filter((review) => review.order_id !== orderId)],
              }
            })
          }}
          closeModalFunc={() => {
            setModalInfo({ chat_dialog_id: "", order_id: "" })
          }}
        />
      )}
    </>
  )
}

export default ReviewsLayout
