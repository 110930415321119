import { useRef, useState } from "react"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import Button from "../../Assets/Button/Button"
import { PlusIcon } from "../../../icons/PlusIcon"
import { CrossIcon } from "../../../icons/CrossIcon"
import SelectReference from "../../SelectReference/SelectReference"
import OrderModalBlock from "../../Modals/OrderModal/OrderModalBlock"
import { TDirectory } from "../../../types/orderTypes"
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import { useTranslation } from "react-i18next"
import styles from "./OrderServiceReference.module.scss"

const OrderServiceReference = ({
  onSubmit,
  onClear,
  type,
  title,
  error,
  isRequired,
  isMultiple,
}: {
  onSubmit: (ids: string[]) => void
  onClear: (ids: string[]) => void
  type: TDirectory
  title: string
  error?: boolean
  isRequired?: boolean
  isMultiple?: boolean
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()
  const { allowScroll } = useScrollBlock()

  const [isDropDownOpen, setDropDownOpen] = useState<boolean>(false)
  const selectUserBtnRef = useRef<HTMLDivElement>(null)
  const [isDropDownTop, setDropDownTop] = useState<boolean>(false)

  useOnClickOutside(selectUserBtnRef, () => {
    if (!isDesktop) return
    if (isDropDownOpen) {
      setDropDownOpen(false)
    }
  })

  const [data, setData] = useState<{ id: string; name: string; avatar_id?: string }[]>([])

  const handleOpen = () => {
    if (selectUserBtnRef?.current && isDesktop) {
      const bottom = selectUserBtnRef.current.getBoundingClientRect().bottom
      setDropDownTop(window.innerHeight - bottom < 250)
    }
    setDropDownOpen((prev) => !prev)
  }

  const handleClear = (id: string) => {
    setData((prev) => prev.filter((item) => item.id !== id))
    const findElems = data.filter((item) => item.id !== id)?.map((item) => item.id)
    onClear(findElems || [])
  }

  const handleSubmit = (data: { id: string; name: string; avatar_id?: string }[]) => {
    onSubmit(data.map((item) => item.id))
    setData(data)
    setDropDownOpen(false)
    if (!isDesktop) allowScroll(true)
  }

  return (
    <OrderModalBlock title={title} isRequired={isRequired} error={error}>
      <div ref={selectUserBtnRef} className={clsx(styles.block, error && [styles.error, "error"])}>
        {data?.map((item) => {
          return (
            <div key={item.id} className={styles.currentUser}>
              <span className={styles.currentUserName}>{item?.name}</span>
              <button type={"button"} className={styles.currentUserBtn} onClick={() => handleClear(item.id)}>
                <CrossIcon />
              </button>
            </div>
          )
        })}

        {(isMultiple || !data?.length) && (
          <Button
            type={"button"}
            txt={
              <>
                <PlusIcon />
                {t("add")}
              </>
            }
            mode={"light"}
            className={styles.btn}
            onClick={handleOpen}
          />
        )}

        {isDropDownOpen && (
          <>
            {isDesktop ? (
              <SelectReference
                type={type}
                list={data}
                isTop={isDropDownTop}
                isMultiple={isMultiple}
                onSubmit={(data) => handleSubmit(data)}
              />
            ) : (
              <ModalPortal
                isOpen={isDropDownOpen}
                setIsOpen={setDropDownOpen}
                header={<h2 className={"modal__title"}>{title}</h2>}
                className={styles.modal}
              >
                <SelectReference
                  type={type}
                  list={data}
                  isMultiple={isMultiple}
                  onSubmit={(data) => handleSubmit(data)}
                  onClose={() => {
                    setDropDownOpen(false)
                    allowScroll(true)
                  }}
                />
              </ModalPortal>
            )}
          </>
        )}
      </div>
    </OrderModalBlock>
  )
}

export default OrderServiceReference
