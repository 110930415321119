import { useEffect } from "react"
import {
  setAuthSupport,
  setIsAllowProposeNewServiceMain,
  setIsAllowRegistration,
  setIsAllowUsersInLK,
} from "../redux/slice/auth"
import { useAppDispatch } from "./index"
import { useGetSettingsQuery } from "../redux/api/auth"
import { hexToRgbValue } from "../utils/hexToRgbValue"
import { getApiUrl } from "../utils/getApiUrl"
import { setCompanyLogo } from "../redux/slice/params"

const useSetSettings = () => {
  const dispatch = useAppDispatch()
  const { data: settings } = useGetSettingsQuery()

  useEffect(() => {
    if (!settings) return

    dispatch(setIsAllowRegistration(settings.allow_registration_from_services))
    dispatch(setIsAllowProposeNewServiceMain(settings.is_allow_prorose_new_service))
    dispatch(setIsAllowUsersInLK(settings?.allow_users_in_lk === "1"))
    if (settings.project_accent_color) {
      const rgbColorResult = hexToRgbValue(settings.project_accent_color)
      document.documentElement.style.setProperty(
        "--accent-color-rgb",
        `${rgbColorResult.red}, ${rgbColorResult.green}, ${rgbColorResult.blue}`,
      )
      document.documentElement.style.setProperty("--accent-color", settings.project_accent_color)
    }
    if (settings.project_text_color) {
      document.documentElement.style.setProperty("--accent-text-color", settings.project_text_color)
    }

    dispatch(
      setAuthSupport({
        email: settings?.support_email || "",
        phone: settings?.support_phone || "",
        site: settings?.support_site || "",
        telegram: settings?.support_telegram || "",
      }),
    )
  }, [settings])

  useEffect(() => {
    try {
      fetch(`${getApiUrl()}/public/photo/company-logo`).then((response) => {
        if (response.headers.get("Content-Type") === "image/svg+xml") {
          return response.text().then((data) => {
            dispatch(setCompanyLogo({ type: response.headers.get("Content-Type") || "", data: data }))
          })
        } else if (response.headers.get("Content-Type") === "application/json") {
          dispatch(setCompanyLogo({ type: response.headers.get("Content-Type") ?? "", data: "/img/logo-staq-ru.svg" }))
        } else {
          return response
            .blob()
            .then((blob) => {
              return {
                contentType: response.headers.get("Content-Type") || "",
                raw: blob,
              }
            })
            .then((data) => {
              dispatch(setCompanyLogo({ type: data.contentType, data: URL.createObjectURL(data.raw) }))
            })
        }
      })
    } catch (err) {
      console.error(err)
    }
  }, [])
}

export default useSetSettings
