import clsx from "clsx"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IReview } from "../../types/content"
import { LInkIcon } from "../../icons/LinkIcon"
import { Dispatch, SetStateAction } from "react"
import useWindowSize from "../../hooks/useWindowSize"
import UserAvatars from "../Assets/UserAvatars/UserAvatars"
import styles from "./Review.module.scss"

interface Props extends IReview {
  setModalInfo: Dispatch<SetStateAction<{ chat_dialog_id: string; order_id: string }>>
  className?: string
}

const Review = ({
  order_id,
  order_name,
  chat_dialog_id,
  text,
  created_at,
  assigneeUsers,
  rating,
  setModalInfo,
  className,
}: Props) => {
  const { isDesktop } = useWindowSize()

  const openModal = () => {
    setModalInfo({ chat_dialog_id: chat_dialog_id, order_id: order_id })
  }

  return (
    <>
      <div
        className={clsx(styles.item, className)}
        onClick={() => {
          if (!isDesktop) openModal()
        }}
      >
        <h4 className={styles.title}>
          <span>{order_name}</span>
          {!isDesktop && (
            <StarsRating
              className={styles.stars}
              rating={rating}
              size={"sm"}
              name={"review-rate-" + order_id}
              disabled
            />
          )}
          {isDesktop && (
            <button type={"button"} className={styles.btnLink} onClick={openModal}>
              <LInkIcon />
            </button>
          )}
          {!isDesktop && <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>}
        </h4>
        {text && <p className={styles.txt}>{text}</p>}
        <div className={clsx(styles.assignees, !isDesktop && styles["assignees--more"])}>
          <UserAvatars items={assigneeUsers} className={styles.avatars} />
          <div>
            {assigneeUsers?.map((assignee, index) => {
              if (index > 4) return
              const suf = index + 1 !== assigneeUsers.length ? ", " : ""
              const more = index === 4 && assigneeUsers.length > 5 ? `еще ${assigneeUsers.length - 5}..` : ""
              return (
                <span key={assignee.id} className={styles.name}>
                  {assignee.name}
                  {suf}
                  <span>{more}</span>
                </span>
              )
            })}
          </div>
          {isDesktop && (
            <>
              <StarsRating
                className={styles.stars}
                rating={rating}
                size={"sm"}
                name={"review-rate-" + order_id}
                disabled
              />
              <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Review
